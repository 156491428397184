import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`An ECDN server.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">type</GQLKeyword> Server <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># ID of ECDN server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">id</GQLName>: <GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Fully Qualified Domain Name of ECDN server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">fqdn</GQLName>: <GQLLink href="/ecdn-api-scalars/FQDN" mdxType="GQLLink">FQDN</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Location of server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">location</GQLName>: <GQLLink href="/ecdn-api-objects/Location" mdxType="GQLLink">Location</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># The last time this server last checked in to the API.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Null if server never checked in before.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">lastCheckIn</GQLName>: <GQLLink href="/ecdn-api-scalars/Time" mdxType="GQLLink">Time</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Whether server is online or not (based on it's last check in date).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">online</GQLName>: <GQLLink href="/ecdn-api-scalars/Boolean" mdxType="GQLLink">Boolean</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Health indicators of server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Null (unknown) if server hasn't reported activity recently</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># (turned off or cannot reach ECDN API).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">health</GQLName>: <GQLLink href="/ecdn-api-objects/ServerHealth" mdxType="GQLLink">ServerHealth</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Current configuration of server. Null if server never checked in before.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">currentConfiguration</GQLName>: <GQLLink href="/ecdn-api-objects/ServerConfiguration" mdxType="GQLLink">ServerConfiguration</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Desired configuration of server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">desiredConfiguration</GQLName>: <GQLLink href="/ecdn-api-objects/ServerConfiguration" mdxType="GQLLink">ServerConfiguration</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Update status of server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">update</GQLName>: <GQLLink href="/ecdn-api-objects/Update" mdxType="GQLLink">Update</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Hardware configuration of server. Null if server never checked in before.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">hardware</GQLName>: <GQLLink href="/ecdn-api-objects/Hardware" mdxType="GQLLink">Hardware</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Video delivery statistics of server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">videoDelivery</GQLName>: <GQLLink href="/ecdn-api-objects/VideoDelivery" mdxType="GQLLink">VideoDelivery</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Resource usages of server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">usages</GQLName>: <GQLLink href="/ecdn-api-objects/ServerUsage" mdxType="GQLLink">ServerUsage</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># What time was this server created and by whom.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">created</GQLName>: <GQLLink href="/ecdn-api-objects/RecordedAction" mdxType="GQLLink">RecordedAction</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Last time this server was modified and by whom.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">modified</GQLName>: <GQLLink href="/ecdn-api-objects/RecordedAction" mdxType="GQLLink">RecordedAction</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Fields`}</h2>
    <h3>{`id: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/ID"
      }}>{`ID`}</a>{`!`}</h3>
    <p>{`ID of ECDN server.`}</p>
    <h3>{`fqdn: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/FQDN"
      }}>{`FQDN`}</a>{`!`}</h3>
    <p>{`Fully Qualified Domain Name of ECDN server.`}</p>
    <h3>{`location: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/Location"
      }}>{`Location`}</a>{`!`}</h3>
    <p>{`Location of server.`}</p>
    <h3>{`lastCheckIn: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Time"
      }}>{`Time`}</a></h3>
    <p>{`The last time this server last checked in to the API.
Null if server never checked in before.`}</p>
    <h3>{`online: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Boolean"
      }}>{`Boolean`}</a>{`!`}</h3>
    <p>{`Whether server is online or not (based on it’s last check in date).`}</p>
    <h3>{`health: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/ServerHealth"
      }}>{`ServerHealth`}</a></h3>
    <p>{`Health indicators of server.
Null (unknown) if server hasn’t reported activity recently
(turned off or cannot reach ECDN API).`}</p>
    <h3>{`currentConfiguration: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/ServerConfiguration"
      }}>{`ServerConfiguration`}</a></h3>
    <p>{`Current configuration of server. Null if server never checked in before.`}</p>
    <h3>{`desiredConfiguration: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/ServerConfiguration"
      }}>{`ServerConfiguration`}</a>{`!`}</h3>
    <p>{`Desired configuration of server.`}</p>
    <h3>{`update: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/Update"
      }}>{`Update`}</a>{`!`}</h3>
    <p>{`Update status of server.`}</p>
    <h3>{`hardware: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/Hardware"
      }}>{`Hardware`}</a></h3>
    <p>{`Hardware configuration of server. Null if server never checked in before.`}</p>
    <h3>{`videoDelivery: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/VideoDelivery"
      }}>{`VideoDelivery`}</a>{`!`}</h3>
    <p>{`Video delivery statistics of server.`}</p>
    <h3>{`usages: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/ServerUsage"
      }}>{`ServerUsage`}</a>{`!`}</h3>
    <p>{`Resource usages of server.`}</p>
    <h3>{`created: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/RecordedAction"
      }}>{`RecordedAction`}</a>{`!`}</h3>
    <p>{`What time was this server created and by whom.`}</p>
    <h3>{`modified: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/RecordedAction"
      }}>{`RecordedAction`}</a>{`!`}</h3>
    <p>{`Last time this server was modified and by whom.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      